<template>
    <div
      fluid
      class="d-flex flex-row align-start align-stretch pa-0 org-container"
    >
        <v-navigation-drawer
            v-if="sidebarState !== 'HIDDEN' && !showNotFoundOrNoPermissionsError"
            permanent
            class="side-menu"
            width="200px"
            >
            <template v-slot:prepend>
                <v-list-item v-if="$store.state.context === 'microsoft-teams'" dense to="/apps/teams/content" exact color="primary" class="pl-6 py-1">
                    <v-list-item-icon><v-icon>fal fa-chevron-left</v-icon></v-list-item-icon>
                    <v-list-item-title>Microsoft Teams</v-list-item-title>
                </v-list-item>
                <v-list-item v-else dense :to="{ name: 'orgmanager'}" exact color="primary" class="pl-6 py-1">
                    <v-list-item-icon><v-icon>fal fa-chevron-left</v-icon></v-list-item-icon>
                    <v-list-item-title>All Organizations</v-list-item-title>
                </v-list-item>
            </template>
            <template v-slot:append>
                <v-divider />
                <v-list-item>
                    <v-list-item-title v-if="!mini">&nbsp;</v-list-item-title>
                    <v-btn icon @click.stop.prevent="switchMiniMode">
                        <v-icon v-if="!mini">fa-chevron-left</v-icon>
                        <v-icon v-if="mini">fa-chevron-right</v-icon>
                    </v-btn>
                </v-list-item>
            </template>

            <v-divider />
            <v-list dense rounded>

                <template v-for="(item, i) in menuItems">
                    <v-divider v-if="item.divider" :key="i" class="my-4" />
                    <v-subheader v-else-if="item.header" :key="i" class="mt-4" >{{item.text | uppercase}}</v-subheader>
                    <v-list-item v-else :key="i" link :to="item.to && item.to.params.id ? item.to : null" exact color="primary" :title="item.text">
                        <v-list-item-icon><v-icon>fal {{ item.icon }}</v-icon></v-list-item-icon>
                        <v-list-item-title>{{ item.text_key ? $t('nav.account.' + item.text_key) : item.text }}</v-list-item-title>
                    </v-list-item>
                </template>
            </v-list>
        </v-navigation-drawer>

        <div v-if="showNotFoundOrNoPermissionsError" class="content-container mt-16">
          <v-alert type="warning" prominent icon="fa-lock">
            <h3>Organization not found or No Permissions to manage</h3>
            <p>Either the organization does not exit or you do not have permissions to manage it. Please make sure you are logged in with the correct account.</p>
            <p class="mt-8 mb-0">Id:</p>
            <code>{{$route.params.id}}</code>
            <p class="mt-8">
              <v-btn :to="{ name: 'orgmanager'}">My Organizations</v-btn>
            </p>
          </v-alert>
        </div>
        <div v-if="!showNotFoundOrNoPermissionsError" class="grey lighten-4 pa-0 content-container">
            <v-toolbar dense v-if="org" elevation="0" color="white lighten-4" >
              <v-toolbar-title class="ml-4"><router-link class="no-decoration" :to="{name:'orgmanagerView', params: {id: org.id}}">{{org.name}}</router-link></v-toolbar-title>
              <v-spacer></v-spacer>
              <div>
              <v-btn text :href="tenant.eventManagerFaqUrl" target="_blank" class="mr-2">
                  <v-icon class="mr-2">fadl fa fa-question-circle</v-icon>
                  FAQ
              </v-btn>
              <v-btn v-if="org" outlined :to="{name:'organization', params: {id: org.id}, query: { mode: 'noRedirect' }}" class="mr-2">
                  <v-icon small class="mr-2">fadl fa fa-eye</v-icon>
                  View
              </v-btn>
              </div>
            </v-toolbar>
            <v-divider/>

            <router-view class="mx-4" :tenant="tenant" :org="org"></router-view>
        </div>
  </div>
</template>


<script>
import { mapGetters } from "vuex";
import { EventBus } from '@/plugins/eventbus.js';
import orgManagerService from "@/services/orgManagerService";
import siteData from '@/data/site.json'
import OrgUtil from "@/util/orgUtil";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "OrgLayoutBase",
  components: {
  },
  props: {
  },
  data() {
    return {
      tenant: tenant,
      org: null,
      mini: false,
      siteData: siteData,
      showNotFoundOrNoPermissionsError: false,
    };
  },
  async mounted() {
    this.mini = this.$cookies.isKey('em.menu.mini');
    this.loadData();
    
    EventBus.$on('login-state-change', async user => {
      this.loadData();
    });
    
    EventBus.$on('orgmanager-request-save', async () => {
      const saved = await this.orgUtil.save();
      if (saved) {
        this.org = this.orgUtil.org; // force refresh down the tree
      }
    });
    EventBus.$on('orgmanager-refresh', async state => {
        console.log('refresh requested', state);
        this.org = state.org; // force refresh down the tree
    });
    EventBus.$on('http-error', async response => {
        if (response.status == 401) {
          this.$helpers.toastResponse(this, { status: "ERROR", msg: "Unauthorized: no permissions to complete this request."});
        }
    });
  },
  methods: {
    async loadData() {
      if (this.user) {
        await this.getOrg(this.$route.params.id);
      }
    },
    async getOrg(id) {
      try {
        this.org = (await orgManagerService.get(id)).data;
        this.showNotFoundOrNoPermissionsError = false;
      }
      catch {
        this.showNotFoundOrNoPermissionsError = true;
      }
    },
    switchMiniMode() {
        this.mini = !this.mini;
        if (this.mini) {
            this.$cookies.set('em.menu.mini', true);
        }
        else {
            this.$cookies.remove('em.menu.mini');
        }
    },
  },
  computed: {
    orgUtil() {
      return new OrgUtil(this, this.org);
    },
    sidebarState() {
      return this.$route.meta && this.$route.meta.sidebar;
    },
    breadcrumbItems() {
      return !this.org ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: 'Organizations', exact: true, to: { name: 'orgmanager' } },
        { text: this.org.name, exact: true, to: { name: 'orgmanagerView', params: {id: this.org.id}} },
      ];
    },
    menuItems() {
      var params = !this.org ? {id: null } : {id: this.org.id };
      return [
          { text: 'Overview', icon: 'fa-home', to: { name: 'orgmanagerView', params: params } },
          { text: 'Events', icon: 'fa-trophy', to: { name: 'orgmanagerEvents', params: params } },
          { text: 'Subscription', icon: 'fa-receipt', to: { name: 'orgmanagerSubscription', params: params } },
          { header: true, text: 'Users' },
          { text: 'Users', icon: 'fa-user', to: { name: 'orgmanagerUsers', params: params } },
          { header: true, text: 'Advanced' },
          { text: 'Integrations', icon: 'fa-puzzle-piece', to: { name: 'orgmanagerIntegrations', params: params } },
          { text: 'Permissions', icon: 'fa-user-unlock', to: { name: 'orgmanagerPermissions', params: params } },
      ].filter(x => x!=null);
    },
    ...mapGetters({
      user: "user"
    })
  },
};
</script>

<style lang="scss">
    .width-100p { width: inherit !important;}
    .event-container { width: inherit !important;}
    .event-menu {
      .v-list-item__icon { max-width: 24px !important; min-width: 24px !important; margin-left: 0 !important;}
      .v-list-item__action:first-child, .v-list-item__icon:first-child {margin-right: 24px;}
      .v-list-item {padding-right: 0px;}
    }
    .org-container {
      .side-menu {
        min-width: 200px;
      }
      .content-container {
        flex-grow: 1;
        min-width: 400px;
      }
    }
    .info-container {
      max-width: inherit !important;

      .label { font-size: 80%; opacity: .8; }
      .value { margin-bottom: 10px; }
      .value pre { white-space: pre-wrap;}
      /*.value:last-child { margin-bottom: 0px; ;}*/
    }

</style>

